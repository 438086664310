import algoliasearch from "algoliasearch/lite"
import { sortBy, orderBy } from "lodash"

export const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export const FILTER_ENUM = Object.freeze({
  region: "region",
  year: "year",
  month: "month",
  author: "author",
  artist: "artist",
})

export function getGroups({ fromFacets }) {
  const result = {}
  const mapper = {
    "branch.name": "branches",
    year: "years",
    month: "months",
    "author.name": "authors",
    "acf.article_artist.title": "artists",
  }

  fromFacets.forEach(({ name, data }) => {
    const options =
      name === "year"
        ? orderBy(Object.keys(data), v => v, "desc")
        : sortBy(Object.keys(data))
    result[mapper[name]] = options
  })
  return result
}

export function getFilterControls({
  currentGroups,
  initialGroups,
  currentFilters,
}) {
  // Displays only available filters
  const appliedFilters = Object.entries(currentFilters.taxonomy).reduce(
    (acc, [name, val]) => {
      if (val) {
        acc.push(name)
      }
      return acc
    },
    []
  )

  const mapper = {
    year: "years",
    month: "months",
    region: "branches",
    acf: "acf",
    author: "authors",
  }

  if (appliedFilters.length === 1) {
    const currFilter = appliedFilters[0]
    const res = {
      ...currentGroups,
      // If only one filter applied, for that one filter display all options
      [mapper[currFilter]]: initialGroups[mapper[currFilter]],
    }

    return res
  } else {
    return currentGroups
  }
}
