import React from "react"
import styled from "styled-components"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Container from "src/components/Container"
import SectionStoryGrid from "src/components/SectionStoryGrid"
import Pagination from "src/components/Pagination"
import {
  ContentHeaderDescription,
  ContentHeaderTitle,
} from "src/templates/category"
import { graphql } from "gatsby"

export const query = graphql`
  query postsByAuthorQuery($wordpress_id: Int!, $skip: Int!, $limit: Int!) {
    allWordpressPost(
      filter: { author: { wordpress_id: { eq: $wordpress_id } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        excerpt
        path
        date(formatString: "DD MMMM YYYY")
        format
        branch {
          name
        }
        author {
          name
          path
        }
        category {
          name
        }
        featured_media {
          ...MediaFragment
        }
        acf {
          article_collaboration
          article_video
          article_audio
        }
      }
    }
  }
`

export default props => {
  const {
    data: { allWordpressPost },
    pageContext: {
      pageNumber,
      basePath,
      numPages,
      name,
      description,
      wordpress_id,
      skip,
      limit,
    },
  } = props
  return (
    <>
      <SEO title={name} />
      <Ad type="author/before_header" />
      <PageWrapper>
        <Header dark={true} />
        <ContentHeader>
          <Container>
            <ContentHeaderTitle>{name}</ContentHeaderTitle>
            {description && <ContentHeaderDescription>{description}</ContentHeaderDescription>}
          </Container>
        </ContentHeader>
        <SectionStoryGrid layout="author" items={allWordpressPost.nodes} />
        <Pagination {...{ numPages, basePath, pageNumber }} />
      </PageWrapper>
      <Ad type="author/before_footer" />
    </>
  )
}

const ContentHeader = styled.div`
  padding-top: 66px;
`