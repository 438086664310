import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import Container from "src/components/Container"
import StoryTeaser from "src/components/StoryTeaser"
import Ad from "src/components/Ad"

export default ({ layout, items }) => {
  const list = useRef()

  // align titles of first 3 items on homepage-1 grid
  function alignTitles() {
    const texts = [
      ...list.current.querySelectorAll("div[class^='StoryTeaser__Text']"),
    ].slice(0, 3)
    let topHeight = 0
    texts.forEach(item => {
      const height = item.offsetHeight
      topHeight = height > topHeight ? height : topHeight
    })
    texts.forEach(item => {
      item.style.minHeight = topHeight + "px"
      item.style.visibility = "visible"
    })
    return true
  }

  function onLayoutReady(fn) {
    setTimeout(
      () =>
        (document.querySelector("html").classList.contains("wf-active") &&
          fn()) ||
        onLayoutReady(fn),
      100
    )
  }

  useEffect(() => {
    if (
      layout === "homepage-1" &&
      typeof window !== "undefined" &&
      window.innerWidth >= breakpoints.tablet
    ) {
      onLayoutReady(alignTitles)
    }
  }, [])

  return (
    <Wrapper className={`-layout-${layout}`} data-items-count={items.length}>
      <Container>
        <List ref={list}>
          {items.map((item, index) => (
            <StoryTeaser
              data={item}
              layout={
                ["category", "author", "search"].includes(layout)
                  ? "category"
                  : layout
              }
              index={index}
              key={index}
            />
          ))}
          {layout === "homepage-1" && <Ad type="homepage/grid_1" />}
          {layout === "homepage-2" && <Ad type="homepage/grid_2" />}
          {layout === "homepage-3" && <Ad type="homepage/grid_3" />}
          {layout === "homepage" && <Ad type="homepage/grid_3" />}
          {layout === "category" && <Ad type="category/grid" />}
          {layout === "author" && <Ad type="author/grid" />}
          {layout === "search" && <Ad type="search/grid" />}
        </List>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 60px;

  &.-layout-category,
  &.-layout-search,
  &.-layout-author {
    margin-bottom: 110px;
  }

  ${media.tablet} {
    margin-bottom: 125px;
  }
`

const List = styled.div`
  ${media.tablet} {
    display: grid;
    grid-template-columns: repeat(6, 0.876666667fr) repeat(2, 1.37fr);
    grid-gap: 10px;

    .-layout-category &,
    .-layout-search &,
    .-layout-author & {
      grid-template-columns: repeat(
        3,
        calc((100vw - ${p => p.theme.sidePadding.desktop}px * 2 - 20px) / 3)
      );
    }
  }

  ${media.desktop} {
    grid-template-columns: repeat(6, calc((100% - 360px) / 6)) repeat(2, 145px);

    .-layout-homepage-1 & {
      grid-template-rows: auto 250px 173px auto;
    }
  }

  ${media.desktopMedium} {
    .-layout-homepage-1 & {
      grid-template-rows: auto 250px 340px auto;
    }

    .-layout-category &,
    .-layout-search &,
    .-layout-author & {
      grid-template-columns: repeat(4, calc((100% - 10px * 7) / 8 * 2 + 10px));
    }
  }


  @media (min-width: 1280px) {
    .-layout-homepage-1 & {
      grid-template-rows: auto 250px auto auto;
    }
  }
`
