import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Container from "src/components/Container"

export default props => {
  const { numPages, pageNumber, basePath, onPageChange } = props
  const getPageLinkForPage = i => (i > 1 ? `${basePath}${i}/` : basePath)

  const nextPage =
    numPages > pageNumber ? getPageLinkForPage(pageNumber + 1) : null
  const prevPage = pageNumber > 1 ? getPageLinkForPage(pageNumber - 1) : null
  const commonPaginationLinkProps = page =>
    typeof onPageChange === "function"
      ? {
          onClick: e => {
            e.preventDefault()
            onPageChange(page)
          },
          as: "button",
        }
      : {}

  function getPagination() {
    const all = [...Array(numPages).keys()]
    const padding = 4
    const index = all.indexOf(pageNumber - 1)
    return [
      ...all.slice(index - padding > 0 ? index - padding : 0, index),
      ...all.slice(index, index + padding + 1),
    ]
  }

  return (
    <Container>
      <Pagination>
        <PaginationLinkContainer>
          {prevPage && (
            <PaginationLink
              {...commonPaginationLinkProps(pageNumber - 1)}
              to={prevPage}
            >
              Previous
            </PaginationLink>
          )}
        </PaginationLinkContainer>
        <PaginationList>
          {/* {pageIndex > 0 && (
              <PaginationListItem>
                <PaginationListLink to="/news">…</PaginationListLink>
              </PaginationListItem>
            )} */}
          {getPagination().map(index => (
            <PaginationListItem
              className={index === pageNumber - 1 ? "-active" : ""}
              key={index}
            >
              <PaginationListLink
                {...commonPaginationLinkProps(index + 1)}
                to={getPageLinkForPage(index + 1)}
              >
                {index + 1}
              </PaginationListLink>
            </PaginationListItem>
          ))}
          {/* <PaginationListItem>
              <PaginationListLink to="/news">…</PaginationListLink>
            </PaginationListItem> */}
        </PaginationList>
        <PaginationLinkContainer>
          {nextPage && (
            <PaginationLink
              {...commonPaginationLinkProps(pageNumber + 1)}
              to={nextPage}
            >
              Next
            </PaginationLink>
          )}
        </PaginationLinkContainer>
      </Pagination>
    </Container>
  )
}

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-top: 50px;
  font-size: 14px;
  line-height: 40px;
  top: -110px;
  position: relative;

  ${media.tablet} {
    padding-top: 11px;
  }
`

const PaginationLinkContainer = styled.div`
  width: 100px;

  &:last-child {
    text-align: right;
  }
`

const PaginationLink = styled(Link)`
  display: inline-block;
  font-weight: 600;
`

const PaginationList = styled.ul`
  flex: 1;
  display: flex;
  justify-content: center;
`

const PaginationListItem = styled.li`
  &.-active {
    font-weight: 600;
  }
`

const PaginationListLink = styled(Link)`
  display: inline-block;
  padding: 0 20px;
`
