import React, { useState, useMemo, useEffect, useRef } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Container from "src/components/Container"
import SectionStoryGrid from "src/components/SectionStoryGrid"
import FilterControl from "src/components/FilterControl"
import Pagination from "src/components/Pagination"
import { PrimaryTitle, Paragraph } from "src/components/Typography"
import { navigate } from "gatsby"
import isAdBlockON from "src/utils/isAdBlockON"
import {
  InstantSearch,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom"
import {
  searchClient,
  FILTER_ENUM,
  getGroups,
  getFilterControls,
} from "src/utils/templateCategory"
import { getIndexName } from "src/utils/algolia"
import { getMonthNameFromNumber } from "src/utils/filters"
import routesConfig from "src/routes.config"
import locationQueryParams from "src/utils/locationQueryParams"

const AlgoliaHits = connectStateResults(props => {
  const { children, isSearchStalled: isLoading, searchResults } = props
  const { hits, nbHits, facets } = searchResults || {}
  return children({ isLoading, hits, nbHits, facets })
})

function getCurrentPage(skip, limit) {
  return skip / limit + 1
}

export default props => {
  const {
    location,
    pageContext: { numPages, name, description, limit, skip, wordpress_id },
  } = props
  const initialGroups = useRef()

  function initialFilters({ _limit = limit } = {}) {
    return {
      taxonomy: {
        [FILTER_ENUM.region]: "",
        [FILTER_ENUM.year]: "",
        [FILTER_ENUM.month]: "",
        [FILTER_ENUM.author]: "",
        [FILTER_ENUM.artist]: "",
      },
      pagination: {
        limit: _limit,
        skip,
      },
    }
  }

  const [currentFilters, setCurrentFilters] = useState(initialFilters())
  const currentPage = getCurrentPage(
    currentFilters.pagination.skip,
    currentFilters.pagination.limit
  )
  const searchParameters = useMemo(() => {
    const { taxonomy, pagination } = currentFilters
    const facetsMap = {
      "branch.name": taxonomy[FILTER_ENUM.region],
      year: taxonomy[FILTER_ENUM.year],
      month: taxonomy[FILTER_ENUM.month],
      "author.name": taxonomy[FILTER_ENUM.author],
      "acf.article_artist.title": taxonomy[FILTER_ENUM.artist],
    }
    const facetFilters = [`categories.wordpress_id:${wordpress_id}`]

    Object.entries(facetsMap).forEach(([facet, value]) => {
      if (value) {
        facetFilters.push(`${facet}:${value}`)
      }
    })

    return {
      facetFilters,
      facets: Object.keys(facetsMap),
      offset: pagination.skip,
      length: pagination.limit,
    }
  }, [currentFilters, wordpress_id])

  function encodeQueryParams(filters) {
    const {
      taxonomy,
      pagination: { skip, limit },
    } = filters

    navigate(
      routesConfig.categoryListing(location.pathname, {
        [FILTER_ENUM.region]: taxonomy[FILTER_ENUM.region],
        [FILTER_ENUM.year]: taxonomy[FILTER_ENUM.year],
        [FILTER_ENUM.month]: taxonomy[FILTER_ENUM.month],
        [FILTER_ENUM.artist]: taxonomy[FILTER_ENUM.artist],
        [FILTER_ENUM.author]: taxonomy[FILTER_ENUM.author],
        page: getCurrentPage(skip, limit),
      })
    )
  }

  function setCurrentFiltersEnhanced(func) {
    let newVal

    setCurrentFilters(curr => {
      newVal = func(curr)
      return newVal
    })

    encodeQueryParams(newVal)
  }

  function handleFilterChange({ name, value }) {
    setCurrentFiltersEnhanced(curr => ({
      ...curr,
      taxonomy: {
        ...curr.taxonomy,
        [name]: value,
      },
      pagination: {
        ...curr.pagination,
        skip: 0,
      },
    }))
  }

  function resetCurrentFilters() {
    setCurrentFiltersEnhanced(() =>
      initialFilters({
        _limit: currentFilters.pagination.limit,
      })
    )
  }

  function updatePostsPerPage(value) {
    setCurrentFilters(curr => ({
      ...curr,
      pagination: {
        ...curr.pagination,
        limit: value,
      },
    }))
  }

  function handlePageChange(page) {
    setCurrentFiltersEnhanced(curr => ({
      ...curr,
      pagination: {
        ...curr.pagination,
        skip: curr.pagination.limit * (page - 1),
      },
    }))
    window.scroll(0, 0)
  }

  useEffect(() => {
    if (isAdBlockON()) {
      updatePostsPerPage(currentFilters.pagination.limit + 1)
    }

    function handleMenuClick() {
      resetCurrentFilters()
    }

    // reset filters on current menu item click
    document.querySelectorAll("[aria-current='page']").forEach(a => {
      a.addEventListener("click", handleMenuClick)
    })
    return () => {
      document.querySelectorAll("[aria-current='page']").forEach(a => {
        a.removeEventListener("click", handleMenuClick)
      })
    }
  }, [])

  useEffect(() => {
    // Decode query params
    const query = locationQueryParams.parse()
    const page = query.page || 1

    setCurrentFilters(curr => ({
      ...curr,
      taxonomy: {
        [FILTER_ENUM.region]: query[FILTER_ENUM.region],
        [FILTER_ENUM.year]: query[FILTER_ENUM.year],
        [FILTER_ENUM.month]: query[FILTER_ENUM.month],
        [FILTER_ENUM.author]: query[FILTER_ENUM.author],
        [FILTER_ENUM.artist]: query[FILTER_ENUM.artist],
      },
      pagination: {
        ...curr.pagination,
        skip: curr.pagination.limit * (page - 1),
      },
    }))
  }, [location.search])

  const PageContent = ({ hits, isLoading, nbHits, facets = [] }) => {
    // TODO: Handle SSR for InstantSearch https://www.algolia.com/doc/guides/building-search-ui/going-further/server-side-rendering/react/
    const renderAlgoliaData = true // !(!filtersChanged.current || isLoading)
    const pageCount = Math.ceil(
      renderAlgoliaData ? nbHits / currentFilters.pagination.limit : numPages
    )
    const renderedPosts = renderAlgoliaData ? hits : []

    if (!initialGroups.current && facets.length) {
      initialGroups.current = getGroups({ fromFacets: facets })
    }

    if (!(renderedPosts || []).length) return <Wrapper />

    const currentGroups = getGroups({ fromFacets: facets })
    const filterControls = getFilterControls({
      currentGroups,
      initialGroups: initialGroups.current,
      currentFilters,
    })

    return (
      <Wrapper>
        <ContentHeader>
          <Container>
            <ContentHeaderTitle>
              <button title="Reset filters" onClick={resetCurrentFilters}>
                {name}
              </button>
            </ContentHeaderTitle>
            {description && <ContentHeaderDescription>{description}</ContentHeaderDescription>}
            <FilterBar>
              <FilterBarGroup>
                <FilterControl
                  name={FILTER_ENUM.region}
                  value={currentFilters.taxonomy[FILTER_ENUM.region]}
                  title="Channel"
                  options={filterControls.branches}
                  onChange={handleFilterChange}
                />
                <FilterControl
                  name={FILTER_ENUM.year}
                  value={currentFilters.taxonomy[FILTER_ENUM.year]}
                  title="Year"
                  options={filterControls.years}
                  onChange={handleFilterChange}
                />
                <FilterControl
                  name={FILTER_ENUM.month}
                  value={currentFilters.taxonomy[FILTER_ENUM.month]}
                  title="Month"
                  options={filterControls.months}
                  onChange={handleFilterChange}
                  optionFormatter={v => getMonthNameFromNumber(Number(v) - 1)}
                />
              </FilterBarGroup>
              <FilterBarGroup>
                {/*(filterControls.artists || []).length > 0 && (
                  <FilterControl
                    name={FILTER_ENUM.artist}
                    value={currentFilters.taxonomy[FILTER_ENUM.artist]}
                    title="Artists"
                    options={filterControls.artists}
                    onChange={handleFilterChange}
                  />
                )*/}
                {(filterControls.authors || []).length > 0 && (
                  <FilterControl
                    name={FILTER_ENUM.author}
                    value={currentFilters.taxonomy[FILTER_ENUM.author]}
                    title="Authors"
                    options={filterControls.authors}
                    hasSearch={true}
                    searchPlaceholder="Search by author…"
                    onChange={handleFilterChange}
                  />
                )}
              </FilterBarGroup>
            </FilterBar>
          </Container>
        </ContentHeader>

        {/* Grid */}
        <SectionStoryGrid layout="category" items={renderedPosts} />

        {pageCount > 1 ? (
          <Pagination
            onPageChange={handlePageChange}
            numPages={pageCount}
            pageNumber={currentPage}
          />
        ) : null}
      </Wrapper>
    )
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName("Posts")}
    >
      <Configure {...searchParameters} />
      <SEO title={name} />
      <Ad type="category/before_header" />
      <PageWrapper>
        <Header dark={true} />
        <AlgoliaHits>{p => <PageContent {...p} />}</AlgoliaHits>
      </PageWrapper>
      <Ad type="category/before_footer" />
    </InstantSearch>
  )
}

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;

  ${media.desktop} {
    @media (min-height: 700px) {
      min-height: 700px;
    }
  }
`

export const ContentHeader = styled.div`
  padding-top: 66px;
`

export const ContentHeaderTitle = styled(PrimaryTitle)`
  margin-bottom: 30px;
`
export const ContentHeaderDescription = styled(Paragraph)`
  margin-top:-20px;
  margin-bottom: 30px;
  max-width: 600px;
`

export const FilterBar = styled.div`
  margin-top: -3px;
  margin-bottom: 21px;

  ${media.tablet} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
  }
`

export const FilterBarGroup = styled.div`
  position: relative;

  &:nth-child(n + 2) {
    margin-top: 10px;
  }

  ${media.tablet} {
    &:nth-child(n + 2) {
      margin-top: 0;
    }
  }
`
